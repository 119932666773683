/* Packages */
import React,{useEffect} from 'react';
import Parse from 'parse';


const CryptoCard = (props) => {
  const usr = Parse.User.current();

  return(
    (<div key={ props.crypto.id }>
        <div className="uk-card uk-card-default uk-card-body">
            <h3 className="uk-card-title"> <a href={"https://www.binance.com/en/trade/"+props.crypto.name} target="_blank">{ props.crypto.name }    </a>

            {usr && props.crypto.user.id == usr.id &&
              <span className="uk-margin-small-right" onClick={props.cb} uk-icon="trash"></span>}
            </h3>
            <p> price : { props.crypto.price2 }  hourly { Math.round((props.crypto.price2 - props.crypto.price)/props.crypto.price*100, 2) }%
                last update { ( props.crypto.updatedAt && props.crypto.updatedAt.toLocaleString()) }
            </p>
        </div>
    </div>)
  );
}

export default CryptoCard;
